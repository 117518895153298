<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - チャネル</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <div v-else class="col-lg-30 mb-3">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-8">チャネル</th>
                        <th class="text-center col-4">HOT/COLD</th>
                        <th class="text-center col-4">反響分類</th>
                        <th class="text-center col-4">集計除外</th>
                        <th class="text-center col-5"></th>
                    </tr>
                </thead>
                <template v-if="channels.length">
                    <draggable
                            v-model="channels"
                            item-key="channel_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input v-model="element.channel_name"/>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="element.temperature"
                                        :options="Temperature.options()"
                                    ></form-select>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="element.channel_category"
                                        :options="ChannelCategory.options()"
                                    ></form-select>
                                </td>
                                <td class="align-middle">
                                    <form-checkbox
                                        v-model="element.is_excluded_from_stats"
                                        option_label="集計除外"
                                    ></form-checkbox>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle">
                            <form-input v-model="channel_to_create.channel_name" />
                        </td>
                        <td class="align-middle">
                            <form-select
                                v-model="channel_to_create.temperature"
                                :options="Temperature.options()"
                            ></form-select>
                        </td>
                        <td class="align-middle">
                            <form-select
                                v-model="channel_to_create.channel_category"
                                :options="ChannelCategory.options()"
                            ></form-select>
                        </td>
                        <td class="align-middle">
                            <form-checkbox
                                v-model="channel_to_create.is_excluded_from_stats"
                                option_label="集計除外"
                            ></form-checkbox>
                        </td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <div class="alert alert-warning"><i class="bi bi-exclamation-circle"></i> 反響分類は、案件に送信元ページが指定されている場合、送信元ページの反響分類が優先されます。</div>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Channel from '@/models/entities/channel';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import FormCheckbox from '@/components/forms/FormCheckbox';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import ChannelCategory from '@/models/enums/channel-category';
import Temperature from '@/models/enums/temperature';

export default {
    name: 'MasterChannel',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        FormSelect,
        FormCheckbox,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // 反響分類option
            ChannelCategory,
            // HOT/COLD option
            Temperature,
            // ローディング
            loading: false,
            // 表示データ
            channels: [],
            // 新規
            channel_to_create: new Channel(),
            // 削除
            channel_to_remove: null,
        }
    },
    mounted() {
        this.fetchChannels();
    },
    methods: {
        // 一覧取得
        fetchChannels() {
            //ローディング開始
            this.loading = true;

            //表示データ初期化
            this.channels.splice(0);

            this.$http.get('/master/channel')
            .then(response => {
                for (let row of response.data) {
                    this.channels.push(new Channel(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        // 新規登録
        create() {
            // バリデーション
            if (this.$helper.isBlank(this.channel_to_create.channel_name)) {
                this.showErrorMessage('チャネル名を入力してください');
                return;
            }
            if (this.$helper.isBlank(this.channel_to_create.temperature)) {
                this.showErrorMessage('HOT/COLDを選択してください');
                return;
            }
            if (this.$helper.isBlank(this.channel_to_create.channel_category)) {
                this.showErrorMessage('反響分類を選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/channel', this.channel_to_create)
            .then(response => {
                this.channels.push(new Channel(response.data));
                this.channel_to_create = new Channel();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(channel) {
            // バリデーション
            if (this.$helper.isBlank(channel.channel_name)) {
                this.showErrorMessage('チャネル名を入力してください');
                return;
            }
            if (this.$helper.isBlank(channel.temperature)) {
                this.showErrorMessage('HOT/COLDを選択してください');
                return;
            }
            if (this.$helper.isBlank(channel.channel_category)) {
                this.showErrorMessage('反響分類を選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/channel/${channel.channel_id}`, channel)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(channel) {
            this.channel_to_remove = channel;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/channel/${this.channel_to_remove.channel_id}`)
            .then(() => {
                const remove_index = this.channels.findIndex((row) => row.channel_id === this.channel_to_remove.channel_id);
                this.channels.splice(remove_index, 1);
                this.channel_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const channels = this.channels.map(channel_object => channel_object.channel_id);

            this.$http.post('/master/channel/sort', {
                channels: channels,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
